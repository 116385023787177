<template>
   <actions-template />
</template>
<script>

// Components
import ActionsTemplate from '@/components/actions/ActionsTemplate.vue'

export default {
  name: 'ActionsView',
  components: {
    ActionsTemplate
  }
}

</script>
