<template>
  <v-container class="unreactive-margins">
    <v-data-table
      :items="actions"
      :headers="headers"
      sort-by="createdOn"
      sort-desc
      class="inverse-header-color"
    >
      <template #[`item.action`]="{ item }">
        <div class="pa-4">
          <v-row class="no-gutters">
            <v-col>
              <span class="text-h6 font-weight-bold">{{ item.name }}</span>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col>{{ item.description }}</v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col>
              <div class="mt-2">
                <v-btn
                  v-if="item.actionStatusId == activeStatusId && !dismissed"
                  class="mr-2"
                  elevation="0"
                  @click="dismissAction(item)"
                  >Dismiss</v-btn
                >
                <v-btn
                  v-if="
                    item.actionCategoryId == mandatoryActionTypeId &&
                    item.actionStatusId == activeStatusId
                  "
                  @click="takeAction(item)"
                  class="mr-2"
                  color="indigo white--text"
                  elevation="0"
                  >TAKE ACTION</v-btn
                >
                <v-btn
                  v-else-if="
                    item.actionCategoryId == mandatoryActionTypeId &&
                    item.actionStatusId == completedStatusId &&
                    !dismissed
                  "
                  @click="takeAction(item)"
                  class="mr-2"
                  color="primary"
                  elevation="0"
                  >VIEW</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
      <template #[`item.type`]="{ item }">
        <v-chip small :color="chipColor(item.actionCategory.name)">{{
          item.actionCategory.name
        }}</v-chip>
      </template>
      <template #[`item.createdOn`]="{ item }">
        {{ formatDate(item.createdOn) }}
      </template>
      <template #[`item.workspace`]="{ item }">
        {{ item.workspace.name }}
      </template>
      <template #[`item.completedOn`]="{ item }">
        {{ item.processedOn ? formatDate(item.processedOn) : "-" }}
      </template>
      <template #[`item.completedBy`]="{ item }">
        {{
          item.processedBy
            ? item.processedBy.firstName + " " + item.processedBy.lastName
            : "-"
        }}
        <!-- change to email? -->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { chipColorClass } from '@/helpers/StylingHelper.js'
import { dateFromUTC } from '@/helpers/FormatHelper.js'
export default {
  props: {
    actions: {
      type: Array,
      default: () => {}
    },
    dismissed: {
      type: Boolean,
      defeault: false
    },
    completed: {
      type: Boolean,
      defeault: false
    }
  },
  data: () => ({
    mandatoryActionTypeId: 1,
    informationalActionTypeId: 2,
    activeStatusId: 1,
    completedStatusId: 2
  }),
  computed: {
    headers () {
      const headers = []
      headers.push({ text: 'Action', value: 'action', width: '50%' })
      headers.push({ text: 'Type', value: 'type', align: 'center' })
      headers.push({ text: 'Created', value: 'createdOn' })
      headers.push({ text: 'Workspace', value: 'workspace' })
      if (this.completed) {
        headers.push({ text: 'Completed On', value: 'completedOn' })
        headers.push({ text: 'Completed By', value: 'completedBy' })
      }
      return headers
    }
  },
  methods: {
    async dismissAction (item) {
      if (!confirm('Are you sure you want to dismiss?')) return
      const res = await this.$api.actions.get(
        `notifications/${item.id}/dismiss`
      )
      if (res) {
        this.$store.dispatch(
          'app/showSuccess',
          `Successfully dismissed Action: ${item.name}`
        )
        await this.$store.dispatch('action/refreshActionCount')
        this.$emit('loadNewActions')
        this.$emit('loadDismissedActions')
      } else {
        this.$store.dispatch('app/showError', 'Failed to dismiss Action')
      }
    },
    chipColor (name) {
      return chipColorClass(name)
    },
    formatDate (date) {
      return dateFromUTC(date)
    },
    takeAction (item) {
      this.$emit('takeAction', item)
    }
  }
}
</script>
<style>
.container.unreactive-margins {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
  max-width: 100% !important;
}
</style>
