var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"unreactive-margins"},[_c('v-data-table',{staticClass:"inverse-header-color",attrs:{"items":_vm.actions,"headers":_vm.headers,"sort-by":"createdOn","sort-desc":""},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [_c('div',{staticClass:"pa-4"},[_c('v-row',{staticClass:"no-gutters"},[_c('v-col',[_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v(_vm._s(item.name))])])],1),_c('v-row',{staticClass:"no-gutters"},[_c('v-col',[_vm._v(_vm._s(item.description))])],1),_c('v-row',{staticClass:"no-gutters"},[_c('v-col',[_c('div',{staticClass:"mt-2"},[(item.actionStatusId == _vm.activeStatusId && !_vm.dismissed)?_c('v-btn',{staticClass:"mr-2",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.dismissAction(item)}}},[_vm._v("Dismiss")]):_vm._e(),(
                  item.actionCategoryId == _vm.mandatoryActionTypeId &&
                  item.actionStatusId == _vm.activeStatusId
                )?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"indigo white--text","elevation":"0"},on:{"click":function($event){return _vm.takeAction(item)}}},[_vm._v("TAKE ACTION")]):(
                  item.actionCategoryId == _vm.mandatoryActionTypeId &&
                  item.actionStatusId == _vm.completedStatusId &&
                  !_vm.dismissed
                )?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.takeAction(item)}}},[_vm._v("VIEW")]):_vm._e()],1)])],1)],1)]}},{key:`item.type`,fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","color":_vm.chipColor(item.actionCategory.name)}},[_vm._v(_vm._s(item.actionCategory.name))])]}},{key:`item.createdOn`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdOn))+" ")]}},{key:`item.workspace`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.workspace.name)+" ")]}},{key:`item.completedOn`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.processedOn ? _vm.formatDate(item.processedOn) : "-")+" ")]}},{key:`item.completedBy`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.processedBy ? item.processedBy.firstName + " " + item.processedBy.lastName : "-")+" ")]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }