<template>
  <div>
    <haul-approval-form
      :formIsOpen="approvalFormIsOpen"
      @closeForm="closeForm()"
      @submitForm="(item) => saveApproval(item)"
      :approvalData="approvalData"
    />
    <v-dialog
      v-model="formIsOpen"
      persistent
      fullscreen
    >
      <v-card style="width: 100%">
        <v-card-title>{{ actionType }} Haul Request Approval</v-card-title>
        <v-card-text>
          <v-container class="unreactive-margins">
            <hauls-table
              :haul-requests="haulRequests"
              @openApprovalForm="
                (haulRequest, isExport) =>
                  openApprovalForm(haulRequest, isExport)
              "
              @processApproval="
                (approval, statusId, decisionNote) =>
                  processApproval(approval, statusId, decisionNote)
              "
              @sendApproval="(approval) => sendApproval(approval)"
              @selectDepthAndApprove="
                (approval, callback) =>
                  selectDepthAndApprove(approval, callback)
              "
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="no-gutters">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn text class="mr-2" @click="closeDialog()">Close</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tabs v-model="activeTab">
      <v-tab value="new">NEW ITEMS</v-tab>
      <v-tab value="completed">COMPLETED ITEMS</v-tab>
      <v-tab value="dismissed">DISMISSED</v-tab>
    </v-tabs>

    <v-window v-model="activeTab">
      <v-window-item>
        <actions-table
          :actions="newActions"
          @viewSegment="(item) => viewSegment(item)"
          @viewInfo="(item) => viewInfo(item)"
          @loadNewActions="loadNewActions"
          @loadDismissedActions="loadDismissedActions"
          @takeAction="takeAction"
        />
      </v-window-item>
      <v-window-item>
        <actions-table
          :actions="completedActions"
          @viewSegment="(item) => viewSegment(item)"
          @viewInfo="(item) => viewInfo(item)"
          completed
        />
      </v-window-item>
      <v-window-item>
        <actions-table
          :actions="dismissedActions"
          @viewSegment="(item) => viewSegment(item)"
          @viewInfo="(item) => viewInfo(item)"
          @takeAction="takeAction"
          dismissed
        />
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
// import { ref } from 'vue'
import { mapState, mapGetters } from 'vuex' // import { mapGetters, mapState, mapMutations } from 'vuex'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import HaulsTable from '@/components/hauls/HaulsTable.vue'
import HaulApprovalForm from '@/components/hauls/HaulApprovalForm.vue'
import ActionsTable from '@/components/actions/ActionsTable.vue'

export default {
  components: {
    HaulsTable,
    HaulApprovalForm,
    ActionsTable
  },
  data: () => ({
    newActions: [],
    completedActions: [],
    dismissedActions: [],
    approvalData: {},
    activeTab: 0,
    formIsOpen: false,
    approvalFormIsOpen: false,
    haulRequests: [],
    actionType: null,
    currentActionItem: null,

    generalContractorCompanyTypeId: 1,
    unsentActionTypeId: 1,
    unansweredActionTypeId: 2,
    notFullyAllocatedActionTypeId: 3,
    volumeUnitOfMeasurementTypeId: 1,
    approvalAcceptedStatusId: 2,
    approvalDeclinedStatusId: 3,
    exportTypeId: 1,
    exportHaulRequestTypeId: 1,
    importHaulRequestTypeId: 2,
    haulingCompanyTypeId: 2,
    haulRequestApprovalEntityType: 1,
    haulRequestEntityType: 2
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      unitOfMeasurementTypes: (state) => state.types.unitsOfMeasurement
    }),
    ...mapGetters({
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    isGeneralContractor () {
      const companyProfile = this.selectedCompanyProfile
      return (
        companyProfile &&
        companyProfile.companyTypeId === this.generalContractorCompanyTypeId
      )
    }
  },
  watch: {
    // eslint-disable-next-line quote-props
    selectedCompanyProfile: {
      handler () {
        this.refreshTemplate()
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      await this.$store.dispatch('action/refreshActionCount')
      if (this.newActions.length <= 0) {
        this.loadNewActions()
      }
      if (this.completedActions.length <= 0) {
        this.loadCompletedActions()
      }
      if (this.dismissedActions.length <= 0) {
        this.loadDismissedActions()
      }
    },
    async refreshTemplate () {
      await this.$store.dispatch('action/refreshActionCount')
      this.loadNewActions()
      this.loadCompletedActions()
      this.loadDismissedActions()
    },
    async loadNewActions () {
      const search = createPaginationObject(null, null, [{ name: 'New' }])
      const res = await this.$api.actions.read(search)
      this.newActions = res.actions
    },
    async loadCompletedActions () {
      const search = createPaginationObject(null, null, [
        { name: 'Completed' }
      ])
      const res = await this.$api.actions.read(search)
      this.completedActions = res.actions
    },
    async loadDismissedActions () {
      const search = createPaginationObject(null, null, [
        { name: 'Dismissed' }
      ])
      const res = await this.$api.actions.read(search)
      this.dismissedActions = res.actions
    },
    async loadHaulRequest (id, entityType) {
      let name
      if (entityType === this.haulRequestApprovalEntityType) {
        name = 'HaulRequestApprovalId'
      } else if (entityType === this.haulRequestEntityType) {
        name = 'HaulRequestId'
      }
      const search = createPaginationObject(null, null, [
        { name: name, values: [id] }
      ])
      const res = await this.$api.haulingRequest.read(search)
      const haulRequests = res.haulingRequests.map((haulRequest) => {
        return {
          ...haulRequest,
          expanded: true
        }
      })
      haulRequests.forEach((haulRequest) => {
        haulRequest.haulingCompanyProfile.company = res.companies.find(
          (company) =>
            company.id === haulRequest.haulingCompanyProfile.companyId
        )
        if (haulRequest.exportingContractorCompanyProfile) {
          haulRequest.exportingContractorCompanyProfile.company =
            res.companies.find(
              (company) =>
                company.id ===
                haulRequest.exportingContractorCompanyProfile.companyId
            )
        }
        if (haulRequest.importingContractorCompanyProfile) {
          haulRequest.importingContractorCompanyProfile.company =
            res.companies.find(
              (company) =>
                company.id ===
                haulRequest.importingContractorCompanyProfile.companyId
            )
        }

        // const loadedHaulRequest = this.haulRequests.find(
        //   (req) => req.id === haulRequest.id
        // )
        // if (loadedHaulRequest) {
        //   haulRequest.tabs = loadedHaulRequest.tabs
        //   haulRequest.selectedTab = loadedHaulRequest.selectedTab
        //   haulRequest.isExport = loadedHaulRequest.isExport
        // } else {
        // eslint-disable-next-line eqeqeq
        const exportTab = haulRequest.exportApprovals.some(
          // eslint-disable-next-line eqeqeq
          (exportApproval) => exportApproval.id == id
        )
        // if (
        //   this.selectedCompanyProfile.companyTypeId !==
        //     this.haulingCompanyTypeId &&
        //   haulRequest.siteId !== this.selectedSiteId
        // ) {
        //   exportTab = !exportTab
        // }
        haulRequest.tabs = exportTab
          ? ['Exports', 'Imports']
          : ['Imports', 'Exports']
        haulRequest.selectedTab = 0
        haulRequest.isExport = haulRequest.typeId === this.exportTypeId
        // }
        haulRequest.totalVolume = this.totalVolume(haulRequest)
      })
      this.haulRequests = haulRequests
    },
    totalVolume (haulRequest) {
      const totals = this.unitOfMeasurementTypes
        .filter(
          (x) => x.unitOfMeasureTypeId === this.volumeUnitOfMeasurementTypeId
        )
        .map((uom) => {
          return {
            exportingVolume: 0,
            allocatedVolume: 0,
            acceptedVolume: 0,
            importingVolume: 0,
            unitOfMeasurement: { ...uom }
          }
        })
      const exports = haulRequest.exportApprovals
      const imports = haulRequest.importApprovals
      const exportPrimary = haulRequest.typeId === this.exportHaulRequestTypeId
      exports.forEach((exp) => {
        const index = totals.findIndex(
          (x) => x.unitOfMeasurement.id === exp.loadUnitOfMeasureId
        )
        const volume = exp.loadVolume * exp.loadQuantity
        totals[index].exportingVolume += volume
        if (
          !exportPrimary &&
          exp.haulRequestApprovalStatusId !== this.approvalDeclinedStatusId
        ) {
          totals[index].allocatedVolume += volume
        }
        if (
          exportPrimary &&
          exp.haulRequestApprovalStatusId === this.approvalAcceptedStatusId
        ) {
          totals[index].acceptedVolume += volume
        }
      })
      imports.forEach((imp) => {
        const index = totals.findIndex(
          (x) => x.unitOfMeasurement.id === imp.loadUnitOfMeasureId
        )
        const volume = imp.loadVolume * imp.loadQuantity
        totals[index].importingVolume += volume
        if (
          exportPrimary &&
          imp.haulRequestApprovalStatusId !== this.approvalDeclinedStatusId
        ) {
          totals[index].allocatedVolume += volume
        }
        if (
          !exportPrimary &&
          imp.haulRequestApprovalStatusId === this.approvalAcceptedStatusId
        ) {
          totals[index].acceptedVolume += volume
        }
      })
      return totals
    },
    async processApproval (approval, statusId, decisionNote) {
      const res = await this.$api.haulingRequest.get(
        `approval/${approval.id}/change-status/${statusId}${
          decisionNote ? `?decisionNote=${decisionNote}` : ''
        }`
      )
      if (res === true) {
        this.$store.dispatch(
          'app/showSuccess',
          `Approval successfully ${
            statusId === this.approvalAcceptedStatusId ? 'accepted' : 'declined'
          }`
        )
        this.$store.dispatch('action/refreshActionCount')
        this.closeDialog()
        // this.loadHaulRequest(approval.id, this.haulRequestApprovalEntityType)
        this.refreshTemplate()
      } else {
        this.$store.dispatch(
          'app/showError',
          `Failed to ${
            statusId === this.approvalAcceptedStatusId ? 'accept' : 'decline'
          } approval.`
        )
      }
      return res
    },
    async sendApproval (approval) {
      const res = await this.$api.haulingRequest.get(
        `approval/${approval.id}/send`
      )
      if (res === true) {
        this.$store.dispatch('app/showSuccess', 'Approval successfully sent')
        this.$store.dispatch('action/refreshActionCount')
        this.closeDialog()
        // this.loadHaulRequest(approval.id, this.haulRequestApprovalEntityType)
        this.refreshTemplate()
      } else this.$store.dispatch('app/showError', 'Failed to send approval.')
    },
    async selectDepthAndApprove (approval, callback) {
      const res = await this.$api.haulingRequest.setImportSegmentDepth(
        approval.id,
        approval.siteSegmentDepthId
      )
      const res2 =
        res === true
          ? await this.processApproval(approval, this.approvalAcceptedStatusId)
          : false
      callback(res2)
    },
    openApprovalForm (
      haulRequest = null,
      isExport = null,
      existingApproval = null
    ) {
      this.approvalFormIsOpen = true
      this.approvalData = {
        haulRequest:
          haulRequest ||
          (existingApproval
            ? this.haulRequests.find(
              (x) => x.id === existingApproval.haulRequestId
            )
            : null),
        isExport: existingApproval ? existingApproval.isExport : isExport,
        existingApproval: existingApproval
      }
    },
    async saveApproval (approval) {
      const res = await (approval.id
        ? this.$api.haulingRequest.put(approval, 'approval')
        : this.$api.haulingRequest.post(approval, 'approval'))
      if (res && res.id) {
        this.$store.dispatch(
          'app/showSuccess',
          `${res.isExport ? 'Export' : 'Import'} Approval ${
            res.code
          } has been ${approval.id ? 'updated' : 'created'}.`
        )
        this.$store.dispatch('action/refreshActionCount')
        this.loadHaulRequest(
          this.currentActionItem.entityId,
          this.currentActionItem.entityTypeId
        )
        this.closeForm()
        this.refreshTemplate()
      } else {
        this.$store.dispatch('app/showError', 'Failed to create approval.')
      }
    },
    openDialog (item) {
      this.currentActionItem = item
      this.loadHaulRequest(item.entityId, item.entityTypeId)
      this.actionType = item.actionType.name
      this.formIsOpen = true
    },
    closeForm () {
      this.approvalFormIsOpen = false
      this.approvalData = {}
    },
    closeDialog () {
      this.currentActionItem = null
      this.formIsOpen = false
    },
    takeAction (item) {
      // if (item.actionTypeId === this.unsentActionTypeId) {
      //   this.openDialog(item)
      // } else if (item.actionTypeId === this.unansweredActionTypeId) {
      //   this.openDialog(item)
      // } else if (item.actionTypeId === this.notFullyAllocatedActionTypeId) {
      //   this.openDialog(item)
      // }
      this.openDialog(item)
    }
  }
}
</script>
<style>
.v-tabs.theme--dark div.v-tabs-bar {
  background-color: #121212 !important;
}
</style>
